import "@/styles/globals.css";
import { useApollo } from "@neonjungle/headless";
import { ApolloProvider } from "@apollo/client";
import type { AppProps } from "next/app";
import localFont from "next/font/local";
import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Player, PlayerEvent } from "@lottiefiles/react-lottie-player";

const alliance = localFont({
	src: "../fonts/AllianceNo.2-Medium.otf",
	variable: "--font-alliance",
	display: "swap",
});

export default function App({ Component, pageProps }: AppProps) {
	const apolloClient = useApollo(pageProps);
	const [showOverlay, setShowOverlay] = useState(true);

	const handleLottieEvent = (event: PlayerEvent) => {
		if (event === "complete") {
			setShowOverlay(false);
		}
	};

	return (
		<ApolloProvider client={apolloClient}>
			<div
				className={`${alliance.variable} lg:grid grid-cols-[80px_1fr] font-sans text-base lg:text-lg ${
					showOverlay ? "overflow-hidden" : ""
				}`}>
				<AnimatePresence>
					{showOverlay && (
						<motion.div
							initial={{ opacity: 1 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.7, delay: 0.8 }}
							className="fixed inset-0 z-50 flex items-center justify-center bg-beige">
							<motion.div
								initial={{ opacity: 1 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.3, delay: 0.3 }}>
								<Player
									autoplay
									keepLastFrame
									onEvent={(event) => handleLottieEvent(event)}
									src="/logo-animation.json"
								/>
							</motion.div>
						</motion.div>
					)}
				</AnimatePresence>
				<Component {...pageProps} />
			</div>
		</ApolloProvider>
	);
}
